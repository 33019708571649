import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useUsersList() {
	// Use toast
	const toast = useToast()

	const refUserListTable = ref(null)

	// Table Handlers
	const tableColumns = [
		{ label: 'Name', key: 'user', sortable: true },
		{ label: 'E-Mail', key: 'email', sortable: true },
		{ label: 'Rolle', key: 'role', sortable: true },
		// {
		// 	label: 'Plan',
		// 	key: 'currentPlan',
		// 	label: 'Plan',
		// 	formatter: title,
		// 	sortable: true,
		// },
		{ label: 'Status', key: 'status', sortable: true },
		{ label: 'Aktionen', key: 'actions' },
	]
	const perPage = ref(10)
	const totalUsers = ref(0)
	const currentPage = ref(1)
	const perPageOptions = [10, 25, 50, 100]
	const searchQuery = ref('')
	const sortBy = ref('createdAt')
	const isSortDirDesc = ref(true)
	const roleFilter = ref(null)
	const planFilter = ref(null)
	const statusFilter = ref(null)

	const dataMeta = computed(() => {
		const localItemsCount = refUserListTable.value ? refUserListTable.value.localItems.length : 0
		return {
			from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
			to: perPage.value * (currentPage.value - 1) + localItemsCount,
			of: totalUsers.value,
		}
	})

	const refetchData = () => {
		refUserListTable.value.refresh()
	}

	watch([currentPage, perPage, searchQuery, roleFilter, planFilter, statusFilter], () => {
		refetchData()
	})

	// @INFO: add wercbrain fetch here

	const fetchUsers = (ctx, callback) => {
		store
			.dispatch('Employees/fetchEmployees', {
				q: searchQuery.value,
				perPage: perPage.value,
				page: currentPage.value,
				sortBy: sortBy.value,
				sortDesc: isSortDirDesc.value,
				role: roleFilter.value,
				plan: planFilter.value,
				status: statusFilter.value,
			})
			.then(response => {
				const { users, total } = response.data

				callback(users)
				totalUsers.value = total
			})
			.catch(() => {
				toast({
					component: ToastificationContent,
					props: {
						title: 'Error fetching users list',
						icon: 'AlertTriangleIcon',
						variant: 'danger',
					},
				})
			})

		// store
		// 	.dispatch('app-user/fetchUsers', {
		// 		q: searchQuery.value,
		// 		perPage: perPage.value,
		// 		page: currentPage.value,
		// 		sortBy: sortBy.value,
		// 		sortDesc: isSortDirDesc.value,
		// 		role: roleFilter.value,
		// 		plan: planFilter.value,
		// 		status: statusFilter.value,
		// 	})
		// 	.then(response => {
		// 		const { users, total } = response.data

		// 		callback(users)
		// 		totalUsers.value = total
		// 	})
		// 	.catch(() => {
		// 		toast({
		// 			component: ToastificationContent,
		// 			props: {
		// 				title: 'Error fetching users list',
		// 				icon: 'AlertTriangleIcon',
		// 				variant: 'danger',
		// 			},
		// 		})
		// 	})
	}

	const deleteUser = userId => {
		store.dispatch('Employees/deleteUser', userId).then(() => {
			refetchData()
		})
	}

	// *===============================================---*
	// *--------- UI ---------------------------------------*
	// *===============================================---*

	const resolveUserRoleVariant = role => {
		// if (role === 'subscriber') return 'primary'
		// if (role === 'author') return 'warning'
		// if (role === 'maintainer') return 'success'
		// if (role === 'editor') return 'info'
		if (role === 'employe') return 'info'
		if (role === 'admin') return 'danger'
		return 'primary'
	}

	const resolveUserRoleIcon = role => {
		// if (role === 'subscriber') return 'UserIcon'
		// if (role === 'author') return 'SettingsIcon'
		// if (role === 'maintainer') return 'DatabaseIcon'
		// if (role === 'editor') return 'Edit2Icon'
		if (role === 'employe') return 'UserIcon'
		if (role === 'admin') return 'ServerIcon'
		return 'UserIcon'
	}

	const resolveUserStatusVariant = status => {
		if (status === 'pending') return 'warning'
		if (status === 'active') return 'success'
		if (status === 'inactive') return 'secondary'
		return 'primary'
	}

	return {
		fetchUsers,
		deleteUser,
		tableColumns,
		perPage,
		currentPage,
		totalUsers,
		dataMeta,
		perPageOptions,
		searchQuery,
		sortBy,
		isSortDirDesc,
		refUserListTable,

		resolveUserRoleVariant,
		resolveUserRoleIcon,
		resolveUserStatusVariant,
		refetchData,

		// Extra Filters
		roleFilter,
		planFilter,
		statusFilter,
	}
}
