<template>
	<UsersView />
</template>

<script>
import UsersView from './users-view/UsersView'
export default {
	name: 'EmployeeDetails',
	components: { UsersView },
}
</script>